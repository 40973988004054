// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use '@angular/material' as mat;
// Plus imports for other components in your app.

// Bootstrap imports


// @import '~bootstrap/scss/bootstrap-reboot';
// @import '~bootstrap/scss/bootstrap-grid';

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();


// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$gestionale15-primary: mat.define-palette(mat.$yellow-palette);
$gestionale15-accent: mat.define-palette(mat.$blue-grey-palette);

// The warn palette is optional (defaults to red).
$gestionale15-warn: mat.define-palette(mat.$red-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$gestionale15-theme: mat.define-dark-theme((
  color: (
    primary: $gestionale15-primary,
    accent: $gestionale15-accent,
    warn: $gestionale15-warn,
  )
));

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($gestionale15-theme);

/* You can add global styles to this file, and also import other style files */

html, body { height: 100%; }
body { font-family: Roboto, "Helvetica Neue", sans-serif; }

app-panel-cart {
  width: 100%;
  height: 100%;
  display: flex;
}

app-go-to-rules-id {
  width: 100%;
  padding: 4px;
}

.header-table-text {
  font-size: 12px;
}

mat-hint {
  font-size: 12px;
}

.mat-mdc-tooltip {
  font-size: 14px !important;
}

app-dialog-edit-row-table {
  display: flex;
  height: 100%;
}

app-purchase-price-session-column {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}

// app-detail-session-wrapper {
//   display: flex;
//   flex-direction: column;
//   width: 100%;
//   height: 100%;
// }

@media screen and (max-width: 900px) {
  app-dialog-edit-row-table {
    display: flex;
    height: 100%;
    flex-direction: column;
  }
}

mat-dialog-container {
  color: white;
}

app-select-warehouse {
  width: 100%;
}

.punto_rosso {
  top: 8px;
  padding: 3px;
  height: 0;
}

.custom_pop_ {
  background-color: #919191;
}


span, p {
  font-size: 14px;
}

.animatedButton_all {
  animation: premis_ 0.5s;

}

.pointer {
  cursor: pointer;
}

@keyframes premis_ {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}
